import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import Kilt from "@kiltprotocol/sdk-js";
import CryptoJS from "crypto-js";
import { u8aToHex } from "@polkadot/util";
import { fileTextIcon } from "@debionetwork/ui-icons";
import { downloadFile, decryptFile } from "@/common/lib/pinata-proxy";
import { queryElectronicMedicalRecordById, queryElectronicMedicalRecordFileById } from "@debionetwork/polkadot-provider";
export default {
  name: "CustomerPHRDetails",
  data: () => ({
    fileTextIcon,
    isLoading: false,
    publicKey: null,
    secretKey: null,
    messageError: null,
    tempDocuments: [],
    result: null,
    message: "Please wait",
    selected: null,
    phrDocument: {}
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      mnemonicData: state => state.substrate.mnemonicData,
      lastEventData: state => state.substrate.lastEventData,
      loadingData: state => state.auth.loadingData
    })
  },
  watch: {
    mnemonicData(val) {
      if (val) this.initialData();
    }
  },
  async created() {
    if (this.mnemonicData) this.initialData();
  },
  methods: {
    async initialData() {
      const cred = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(cred.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(cred.boxKeyPair.secretKey);
      if (cred) await this.prepareData();
    },
    async prepareData() {
      var _this$phrDocument, _this$phrDocument2;
      const {
        id
      } = this.$route.params;
      const data = await queryElectronicMedicalRecordById(this.api, id);
      let files = [];
      if (!id || !data) {
        this.messageError = "Oh no! We can't find your selected order. Please select another one or try again";
        return;
      }
      this.phrDocument = data;
      for (const file of data.files) {
        const dataFile = await queryElectronicMedicalRecordFileById(this.api, file);
        files.push(dataFile);
      }
      this.phrDocument.files = files;
      if ((_this$phrDocument = this.phrDocument) !== null && _this$phrDocument !== void 0 && _this$phrDocument.files.length) await this.parseResult(0, {
        recordLink: (_this$phrDocument2 = this.phrDocument) === null || _this$phrDocument2 === void 0 ? void 0 : _this$phrDocument2.files[0].recordLink
      });
    },
    async parseResult(idx, _ref) {
      let {
        recordLink
      } = _ref;
      let fileBlob, dataFile, decryptedFile;
      if (this.selected === idx) return;
      this.selected = idx;
      try {
        this.isLoading = true;
        const pair = {
          publicKey: this.publicKey,
          secretKey: this.secretKey
        };
        const checkTempDocuments = this.tempDocuments.find(doc => doc.link === recordLink);
        if (!checkTempDocuments) {
          dataFile = await downloadFile(recordLink, true);
          decryptedFile = decryptFile(dataFile.data, pair, dataFile.type);
        }
        if (!checkTempDocuments) this.tempDocuments.push({
          link: recordLink,
          file: decryptedFile,
          type: dataFile.type
        });
        if (checkTempDocuments) fileBlob = window.URL.createObjectURL(new Blob([checkTempDocuments.file], {
          type: checkTempDocuments.type
        }));else fileBlob = window.URL.createObjectURL(new Blob([decryptedFile], {
          type: dataFile.type
        }));
        this.result = fileBlob;
      } catch {
        this.message = "Oh no! Something went wrong. Please try again later";
      } finally {
        this.isLoading = false;
      }
    }
  }
};